import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/macpro/Docs/projects/itzsrv-weblog-prod/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pledge"
    }}>{`Pledge`}</h1>
    {
      /*
      This page contains only content that has the tag **stand and pledge**.
      This works thanks to this code:
      ```jsx
      <FeedItems filterByTags={['stand']} />
      ```
      More about the `FeedItems` component in the docs: [gatsby-theme-chronoblog#feeditems-component](https://github.com/Chronoblog/gatsby-theme-chronoblog#feeditems-component)
      This is convenient for creating pages with your works, portfolio, collecting articles of a certain subject in one place, etc.
      If you do not need an extra page, just remove it from `src/pages/`, and remove link to this page from the main menu: `src/gatsby-theme-chronoblog/site-header.mdx`.
      More about the additional site pages in the docs: [gatsby-theme-chronoblog#pages](https://github.com/Chronoblog/gatsby-theme-chronoblog#pages)
      ## Projects (any content with the "project" tag):
      */
    }
    <p>{`As a society, we don't acknowledge our societal-ills and later on, these gets manifested into major problems that we have today! `}</p>
    <p>{`As an individual, I will do my best to stand for the cause while trying to address the societal issues around me with appropriate solutions, and strengthen the voice against it. `}</p>
    <blockquote>
      <p parentName="blockquote">{`All the pledges that I take, I will be posting here, for my self-reminder and motivation to continue.`}</p>
      <p parentName="blockquote">{`#pledge`}</p>
    </blockquote>
    <FeedItems filterByTags={['stand', 'pledge']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      